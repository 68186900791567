'use client';

import posthog from 'posthog-js';
import { PostHogProvider as PHOriginalProvider } from 'posthog-js/react';
import configuration from '~/configuration';

if (typeof window !== 'undefined') {
  const environment = process.env.NEXT_PUBLIC_APP_ENV ?? 'local';
  const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;
  const posthogHost = process.env.NEXT_PUBLIC_POSTHOG_HOST;

  if (!posthogKey || !posthogHost) {
    console.error('PostHog initialization failed: missing required environment variables.');
  } else {
    posthog.init(posthogKey, {
      api_host: `${configuration.site.siteUrl}/ingest-ph`,
      ui_host: posthogHost,
      autocapture: false,
      capture_pageview: true,
      cross_subdomain_cookie: false,
      loaded: (ph) => {
        // if (configuration.appEnv !== 'production') ph.opt_out_capturing();
        ph.register({ environment });
      },
    });
  }
}

export default function PostHogProvider({ children }: { children: React.ReactNode }) {
  return <PHOriginalProvider client={posthog}>{children}</PHOriginalProvider>;
}
